import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';

import * as Actions from './bindings-check.actions';
import {
  BdsElementItem,
  BindingSourceType,
  DisciplineDto,
  FamilySymbolSetDto,
  FamilyVersionDto,
  FullVersionParameterDto,
  TableFamilyDto,
  ValueSetDto,
} from '@common/dto';
import { BindingsCheckTreeNode, familyVersionParametersAdapter, ICheckElement } from '../utils';
import { bindingsCheckParameterNameValueAdapter } from '../utils/bindings-check-parameter-name-value.adapter';
import { BindingsCheckParametersDrawerEnum } from '../components';
import {
  BindingCheckMaterial,
  BindingsCheckMessage,
  BindingsCheckMessageType,
  BindingsCheckStateParameters,
} from '../interfaces';
import { uuidv4 } from '@common/utils';
import { BindingsApiService, MaterialParametersApiService } from '@services/api';
import { MaterialsParametersFilterParams } from '@common/models/filters';
import { finalize, map, mergeMap, tap } from 'rxjs/operators';
import { materialsCheckParametersAdapter } from '../utils/materials-check-parameters.adapter';
import { BdsFmApiService } from '@services/api/bindings/bds-fm-api.service';
import { IBdsFmFilterParams } from '@common/models/filters/bds-fm';
import { TranslateService } from '@ngx-translate/core';
import { YandexMetrikaService } from '@core/metrika';
import { BindingsCheckService } from '../services';
import { NomenclatureUtilsService } from '@features/elements-check/services/nomenclature-utils.service';
import { BindingDataSource } from '../bindings-check.component';

export interface IBindingsCheckState {
  family: TableFamilyDto;
  version: FamilyVersionDto;
  symbol: FamilySymbolSetDto;
  set: ValueSetDto;
  discipline: DisciplineDto;
  successOnly: boolean;
  includeNotMatchableSpecs: boolean;
  sourceType: BindingSourceType;
  familyParameters: BindingsCheckStateParameters<FullVersionParameterDto>;
  materials: BindingCheckMaterial[];
  checkResponse: any[];
  dataSource: BindingDataSource;
  checkPending: boolean;
  checkMessage: BindingsCheckMessage;
  bdsIds: string[];
  checkElements: ICheckElement[];
  requestStore: {
    family: TableFamilyDto;
    version: FamilyVersionDto;
    symbol: FamilySymbolSetDto;
    set: ValueSetDto;
    discipline: DisciplineDto;
    successOnly: boolean;
    sourceType: BindingSourceType;
    familyParameters: BindingsCheckStateParameters<FullVersionParameterDto>;
    materials: BindingCheckMaterial[];
  };
}

@Injectable()
@State<IBindingsCheckState>({
  name: 'bindingsCheck',
  defaults: {
    family: null,
    version: null,
    symbol: null,
    discipline: null,
    sourceType: BindingSourceType.Snap,
    set: null,
    successOnly: false,
    includeNotMatchableSpecs: false,
    checkPending: false,
    familyParameters: null,
    materials: [],
    bdsIds: [],
    checkElements: [],
    checkMessage: null,
    checkResponse: null,
    requestStore: null,
    dataSource: BindingDataSource.family,
  },
})
export class BindingsCheckState implements NgxsOnInit {
  @Selector()
  static family(state: IBindingsCheckState) {
    return state.family;
  }
  @Selector()
  static sourceType(state: IBindingsCheckState) {
    return state.sourceType;
  }

  @Selector()
  static version(state: IBindingsCheckState) {
    return state.version;
  }

  @Selector()
  static symbol(state: IBindingsCheckState) {
    return state.symbol;
  }

  @Selector()
  static requestStore(state: IBindingsCheckState) {
    return state.requestStore;
  }

  @Selector()
  static set(state: IBindingsCheckState) {
    return state.set;
  }

  @Selector()
  static checkMessage(state: IBindingsCheckState) {
    return state.checkMessage;
  }

  @Selector()
  static checkPending(state: IBindingsCheckState) {
    return state.checkPending;
  }

  @Selector()
  static familyParameters(state: IBindingsCheckState) {
    return state.familyParameters;
  }

  @Selector()
  static materials(state: IBindingsCheckState) {
    return state.materials;
  }

  @Selector()
  static checkResponse(state: IBindingsCheckState) {
    return state.checkResponse;
  }

  @Selector()
  static bdsIds(state: IBindingsCheckState) {
    return state.bdsIds;
  }

  @Selector()
  static checkElements(state: IBindingsCheckState) {
    return state.checkElements;
  }

  @Selector()
  static dataSource(state: IBindingsCheckState) {
    return state.dataSource;
  }

  constructor(
    private materialParametersApi: MaterialParametersApiService,
    private bdsFmApi: BdsFmApiService,
    private translateService: TranslateService,
    private _metrika: YandexMetrikaService,
    public bindingsCheckService: BindingsCheckService,
    private nomenclatureUtilsService: NomenclatureUtilsService,
  ) {}

  ngxsOnInit(ctx: StateContext<IBindingsCheckState>) {
    ctx.patchState({
      checkMessage: {
        type: BindingsCheckMessageType.Primary,
        text: this.translateService.instant('BINDINGS_CHECK_MODULE.MESSAGES.SET_FILTER'),
      },
    });
  }

  @Action(Actions.SetFamily)
  setFamily(ctx: StateContext<IBindingsCheckState>, { family }: Actions.SetFamily) {
    ctx.patchState({ family });

    if (!family) {
      ctx.patchState({ version: null, set: null, familyParameters: null, symbol: null });
    }
  }

  @Action(Actions.SetVersion)
  setVersion(ctx: StateContext<IBindingsCheckState>, { version }: Actions.SetVersion) {
    ctx.patchState({ version });
  }

  @Action(Actions.SetSymbol)
  setSymbol(ctx: StateContext<IBindingsCheckState>, { symbol }: Actions.SetSymbol) {
    ctx.patchState({ symbol });
  }

  @Action(Actions.SetFamilyVersionSet)
  setFamilyVersionSet(ctx: StateContext<IBindingsCheckState>, { set }: Actions.SetFamilyVersionSet) {
    ctx.patchState({ set });
  }

  @Action(Actions.AddEmptyMaterial)
  addEmptyMaterial(ctx: StateContext<IBindingsCheckState>) {
    const state = ctx.getState();

    ctx.patchState({
      materials: [...state.materials, { material: null, parameters: null, localId: uuidv4(), quantity: 1 }],
    });
  }

  @Action(Actions.SetSourceType)
  setSourceType(ctx: StateContext<IBindingsCheckState>, { sourceType }: Actions.SetSourceType) {
    ctx.patchState({
      sourceType,
    });
  }

  @Action(Actions.SetSuccessOnly)
  setSuccessOnly(ctx: StateContext<IBindingsCheckState>, { value }: Actions.SetSuccessOnly) {
    ctx.patchState({
      successOnly: value,
    });
  }

  @Action(Actions.SetIncludeNotMatchableSpecs)
  SetIncludeNotMatchableSpecs(ctx: StateContext<IBindingsCheckState>, { value }: Actions.SetIncludeNotMatchableSpecs) {
    ctx.patchState({
      includeNotMatchableSpecs: value,
    });
  }

  @Action(Actions.SetDiscipline)
  setDiscipline(ctx: StateContext<IBindingsCheckState>, { discipline }: Actions.SetDiscipline) {
    ctx.patchState({
      discipline,
    });
  }

  @Action(Actions.SetMaterialQuantity)
  setMaterialQuantity(ctx: StateContext<IBindingsCheckState>, { localId, quantity }: Actions.SetMaterialQuantity) {
    const state = ctx.getState();

    ctx.patchState({
      materials: state.materials.map((m) =>
        m.localId !== localId
          ? m
          : {
              ...m,
              quantity,
            },
      ),
    });
  }

  @Action(Actions.SetMaterial)
  setMaterial(ctx: StateContext<IBindingsCheckState>, { localId, material }: Actions.SetMaterial) {
    const state = ctx.getState();

    if (!material) {
      return ctx.patchState({
        materials: state.materials.map((m) =>
          m.localId !== localId ? m : { localId, material: null, parameters: null, quantity: 1 },
        ),
      });
    }

    return this.materialParametersApi.getList(new MaterialsParametersFilterParams({ materialIds: [material.id] })).pipe(
      map((data) => data.result),
      tap((parameters) => {
        const checkParameters = materialsCheckParametersAdapter(parameters);
        ctx.patchState({
          materials: state.materials.map((m) =>
            m.localId !== localId
              ? m
              : {
                  localId,
                  material,
                  quantity: m.quantity,
                  parameters: {
                    initialParameters: parameters,
                    checkParameters,
                    validParametersCount: Object.values(bindingsCheckParameterNameValueAdapter(checkParameters)).length,
                  },
                },
          ),
        });
      }),
    );
  }

  @Action(Actions.SetCheckParameters)
  setCheckParameters(ctx: StateContext<IBindingsCheckState>, { data }: Actions.SetCheckParameters) {
    const state = ctx.getState();

    if (data.type === BindingsCheckParametersDrawerEnum.Family) {
      const validParametersCount = Object.values(bindingsCheckParameterNameValueAdapter(data.parameters)).length;

      ctx.patchState({
        familyParameters: { ...state.familyParameters, checkParameters: data.parameters, validParametersCount },
      });
    }

    if (data.type === BindingsCheckParametersDrawerEnum.Material) {
      const validParametersCount = Object.values(bindingsCheckParameterNameValueAdapter(data.parameters)).length;

      ctx.patchState({
        materials: state.materials.map((m) =>
          m.localId === data.id
            ? { ...m, parameters: { ...m.parameters, checkParameters: data.parameters, validParametersCount } }
            : m,
        ),
      });
    }
  }

  @Action(Actions.RemoveMaterial)
  removeMaterial(ctx: StateContext<IBindingsCheckState>, { localId }: Actions.RemoveMaterial) {
    const state = ctx.getState();

    return ctx.patchState({
      materials: state.materials.filter((m) => m.localId !== localId),
    });
  }

  @Action(Actions.SetFunctionalTypeParameters)
  setFunctionalTypeParameters(
    ctx: StateContext<IBindingsCheckState>,
    { initialParameters }: Actions.SetFunctionalTypeParameters,
  ) {
    const parameters = familyVersionParametersAdapter(initialParameters || []);
    const validParametersCount = Object.values(bindingsCheckParameterNameValueAdapter(parameters)).length;

    ctx.patchState({
      familyParameters: {
        initialParameters,
        checkParameters: parameters,
        validParametersCount,
      },
    });
  }

  @Action(Actions.SetBdsIds)
  SetBdsIds(ctx: StateContext<IBindingsCheckState>, { ids }: Actions.SetBdsIds) {
    ctx.patchState({
      bdsIds: ids,
    });
  }

  @Action(Actions.SetDataSource)
  SetDataSource(ctx: StateContext<IBindingsCheckState>, { dataSource }: Actions.SetDataSource) {
    ctx.patchState({
      dataSource,
    });
  }

  @Action(Actions.Check)
  check(ctx: StateContext<IBindingsCheckState>, { isNomenclature }: Actions.Check) {
    const state = ctx.getState();
    ctx.patchState({
      requestStore: {
        family: state.family,
        version: state.version,
        symbol: state.symbol,
        set: state.set,
        discipline: state.discipline,
        successOnly: state.successOnly,
        sourceType: state.sourceType,
        familyParameters: state.familyParameters,
        materials: state.materials,
      },
    });

    const params: IBdsFmFilterParams = isNomenclature
      ? {
          bindingSourceType: state.sourceType,
          successOnly: state.successOnly,
          suggestNomenclatures: true,
          includeNotMatchableSpecs: state.includeNotMatchableSpecs,
          discipline: state.discipline?.name || null,
        }
      : {
          discipline: state.discipline?.name || null,
          bindingSourceType: state.sourceType,
          successOnly: state.successOnly,
        };

    const bdsMaterials: BdsElementItem[] = state.bdsIds?.length
      ? []
      : state.materials.map((material) => ({
          id: material.localId,
          fmGuid: material.localId,
          uniqueId: material.localId,
          category: 'Материалы',
          bdsParameters: {
            ...bindingsCheckParameterNameValueAdapter(material.parameters?.checkParameters || []),
            BDS_MaterialCode: material.material.code,
          },
        }));

    const bdsFamily: BdsElementItem = state.bdsIds?.length
      ? {}
      : {
          id: state.version?.fmId,
          fmGuid: state.version?.fmId,
          uniqueId: state.version?.fmId,
          category: state.version?.category?.name,
          subType: state.symbol?.name,
          type: state.family.name,
          bdsParameters: bindingsCheckParameterNameValueAdapter(state.familyParameters?.checkParameters || []),
          materials: state.materials.map((d) => ({
            Quantity: d.quantity,
            UniqueId: d.localId,
          })),
        };

    ctx.patchState({ checkPending: true });
    return this.bdsFmApi
      .checkBindings({
        ...bdsMaterials,
        bdsElementList: state.bdsIds?.length ? [] : [bdsFamily],
        bdsElementIds: Array.from(new Set(state.bdsIds)),
        ...params,
      })
      .pipe(
        tap((checkElements) => {
          if (!checkElements?.length) return;
          const checkElement = checkElements[0];
          const checkMessage = checkElement.message
            ? {
                text: checkElement.message,
                type: BindingsCheckMessageType.Primary,
              }
            : null;

          const checkResponse =
            state.dataSource === BindingDataSource.bds
              ? checkElements.map((el) => {
                  const children = this.nomenclatureUtilsService.bindingsTreeCheckDataAdapter(el);
                  return {
                    name: el.bdsId,
                    children,
                    expandable: !!children.length,
                  };
                })
              : checkElements.reduce((_, c) => {
                  return [...this.nomenclatureUtilsService.bindingsTreeCheckDataAdapter(c)];
                }, []);

          ctx.patchState({
            checkMessage,
            checkElements: checkElements.map((el) => ({
              bdsId: el.bdsId,
              elements: this.nomenclatureUtilsService.bindingsTreeCheckDataAdapter(el),
            })),
            checkResponse,
          });

          this._metrika.action('BINDINGS_CHECK_GET_RESULT');
        }),
        finalize(() => ctx.patchState({ checkPending: false })),
      );
  }
}
