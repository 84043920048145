import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SortOrder, TypedFormControl } from '@common/interfaces';
import { BehaviorSubject, combineLatest, of, Subject, switchMap } from 'rxjs';
import { BindingSourceType, FullVersionParameterDto, IdNameDto, TableFamilyDto } from '@common/dto';
import { filter, startWith, takeUntil, tap } from 'rxjs/operators';
import { VersionsApiService } from '@services/api';
import { BindingsCheckService } from '@features/bindings-check/services/bindings-check.service';
import {
  BindingsCheckParametersDrawerComponent,
  BindingsCheckParametersDrawerEnum,
} from '@features/bindings-check/components';
import { Store } from '@ngxs/store';
import { BindingsCheckState } from '@features/bindings-check/state';
import { TitleService } from '@core/services';
import { bindingsCheckSourceTypes, EBindingsCheckSourceType } from './types/check-bindings';

export enum BindingDataSource {
  family,
  bds,
}

@Component({
  selector: 'app-bindings-check',
  templateUrl: 'bindings-check.component.html',
  styleUrls: ['bindings-check.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BindingsCheckComponent implements OnInit, OnDestroy {
  @ViewChild('parametersDrawer') parametersDrawer: BindingsCheckParametersDrawerComponent;
  bindingsCheckSourceTypes = bindingsCheckSourceTypes;
  EBindingsCheckSourceType = EBindingsCheckSourceType;
  BDSIds: string[] = [];

  protected readonly SortOrder = SortOrder;
  public readonly familyControl: TypedFormControl<number> = new FormControl();
  public readonly disciplineControl: TypedFormControl<number> = new FormControl();
  public readonly bindingsCheckSourceType: TypedFormControl<number> = new FormControl();
  public readonly sourceTypeControl: TypedFormControl<BindingSourceType> = new FormControl(BindingSourceType.Snap);
  public readonly BindingsCheckParametersDrawerEnum = BindingsCheckParametersDrawerEnum;
  public readonly functionalTypeControl: TypedFormControl<number> = new FormControl(null);
  public readonly versionControl: TypedFormControl<number> = new FormControl(null);
  public readonly setControl: TypedFormControl<number> = new FormControl(null);
  public readonly symbolControl: TypedFormControl<number> = new FormControl(null);
  public readonly functionalTypeList$ = new BehaviorSubject<IdNameDto[]>([]);

  private readonly destroy$ = new Subject();

  constructor(
    private readonly versionsApi: VersionsApiService,
    public bindingsCheckService: BindingsCheckService,
    private store: Store,
    private titleService: TitleService,
  ) {}

  ngOnInit() {
    this.subscribeToFamily().subscribe();
    this.subscribeToSourceType().subscribe();
    this.getVersionSetParameters().subscribe();
    this.titleService.setTranslatedTitle('MENU.ITEM.BINDINGS_CHECK');
    this.bindingsCheckSourceType.setValue(EBindingsCheckSourceType.Family);
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  selectFamily(family: TableFamilyDto) {
    this.emptyFamilyDependencies();
    this.bindingsCheckService.setFamily(family);
    const list = family?.functionalTypes || [];
    this.functionalTypeList$.next(list);
    if (list.length) {
      this.functionalTypeControl.setValue(family.functionalTypes[0].id);
    }
  }

  private subscribeToFamily() {
    return this.familyControl.valueChanges.pipe(
      filter((f) => !f),
      tap(() => this.emptyFamilyDependencies()),
      takeUntil(this.destroy$),
    );
  }

  private emptyFamilyDependencies() {
    this.functionalTypeControl.setValue(null);
    this.symbolControl.setValue(null);
    this.setControl.setValue(null);
    this.versionControl.setValue(null);
  }

  private subscribeToSourceType() {
    return this.sourceTypeControl.valueChanges.pipe(
      tap((value) => this.bindingsCheckService.setSourceType(value)),
      takeUntil(this.destroy$),
    );
  }

  private getVersionSetParameters() {
    return combineLatest([
      this.functionalTypeControl.valueChanges.pipe(startWith(null)),
      this.setControl.valueChanges.pipe(startWith(null)),
    ]).pipe(
      switchMap(([functionalTypeId, setId]) =>
        !!functionalTypeId && !!setId
          ? this.versionsApi.getFamilyVersionFunctionalTypeParameters(setId, functionalTypeId)
          : of([]),
      ),
      tap((parameters: FullVersionParameterDto[]) => this.bindingsCheckService.setFunctionalTypeParameters(parameters)),
      takeUntil(this.destroy$),
    );
  }

  openFamilyParametersDrawer(): void {
    const version = this.store.selectSnapshot(BindingsCheckState.version);
    const familyParameters = this.store.selectSnapshot(BindingsCheckState.familyParameters);

    this.parametersDrawer.open(
      version.fmId,
      familyParameters.checkParameters,
      BindingsCheckParametersDrawerEnum.Family,
    );
  }

  onBindingsCheckSourceTypeChange(dataSource: BindingDataSource): void {
    this.emptyFamilyDependencies();
    this.familyControl.setValue(null);
    this.BDSIds = [];
    this.bindingsCheckService.setBdsIds([]);
    this.bindingsCheckService.setDataSource(dataSource);
  }
}
