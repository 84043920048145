import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { BindingsCheckState } from '@features/bindings-check/state';
import {
  BindingSourceType,
  DisciplineDto,
  FamilySymbolSetDto,
  FamilyVersionDto,
  FullVersionParameterDto,
  MaterialDto,
  TableFamilyDto,
  ValueSetDto,
} from '@common/dto';
import * as Actions from '../state/bindings-check.actions';
import {
  BindingCheckMaterial,
  BindingCheckRequestStore,
  BindingsCheckMessage,
  BindingsCheckParametersDrawerSaveData,
  BindingsCheckStateParameters,
} from '../interfaces';
import { BindingsCheckTreeNode, ICheckElement } from '@features/bindings-check/utils';
import { BindingDataSource } from '../bindings-check.component';

@Injectable({
  providedIn: 'root',
})
export class BindingsCheckService {
  @Select(BindingsCheckState.family) public family$: Observable<TableFamilyDto>;
  @Select(BindingsCheckState.requestStore) public requestStore$: Observable<BindingCheckRequestStore>;
  @Select(BindingsCheckState.sourceType) public sourceType$: Observable<BindingSourceType>;
  @Select(BindingsCheckState.version) public version$: Observable<FamilyVersionDto>;
  @Select(BindingsCheckState.checkPending) public checkPending$: Observable<boolean>;
  @Select(BindingsCheckState.checkMessage) public checkMessage$: Observable<BindingsCheckMessage>;
  @Select(BindingsCheckState.checkResponse) public checkResponse$: Observable<BindingsCheckTreeNode[]>;
  @Select(BindingsCheckState.checkElements) public checkElements$: Observable<ICheckElement[]>;
  @Select(BindingsCheckState.materials) public materials$: Observable<BindingCheckMaterial[]>;
  @Select(BindingsCheckState.dataSource) public dataSource$: Observable<BindingDataSource[]>;
  @Select(BindingsCheckState.familyParameters) public familyParameters$: Observable<
    BindingsCheckStateParameters<FullVersionParameterDto>
  >;

  constructor(private store: Store) {}

  public setFamily(family: TableFamilyDto) {
    this.store.dispatch(new Actions.SetFamily(family));
  }

  public setVersion(version: FamilyVersionDto) {
    this.store.dispatch(new Actions.SetVersion(version));
  }

  public setSymbol(symbol: FamilySymbolSetDto) {
    this.store.dispatch(new Actions.SetSymbol(symbol));
  }

  public setFamilyVersionSet(set: ValueSetDto) {
    this.store.dispatch(new Actions.SetFamilyVersionSet(set));
  }

  public setFunctionalTypeParameters(parameters: FullVersionParameterDto[]) {
    this.store.dispatch(new Actions.SetFunctionalTypeParameters(parameters));
  }

  public setCheckParameters(data: BindingsCheckParametersDrawerSaveData) {
    this.store.dispatch(new Actions.SetCheckParameters(data));
  }

  public addEmptyMaterial() {
    this.store.dispatch(new Actions.AddEmptyMaterial());
  }

  public setMaterial(localId: string, material: MaterialDto) {
    this.store.dispatch(new Actions.SetMaterial(localId, material));
  }

  public setMaterialQuantity(localId: string, quantity: number) {
    this.store.dispatch(new Actions.SetMaterialQuantity(localId, quantity));
  }

  public removeMaterial(localId: string) {
    this.store.dispatch(new Actions.RemoveMaterial(localId));
  }

  public setDiscipline(discipline: DisciplineDto) {
    this.store.dispatch(new Actions.SetDiscipline(discipline));
  }

  public setSourceType(sourceType: BindingSourceType) {
    this.store.dispatch(new Actions.SetSourceType(sourceType));
  }

  public setSuccessOnly(value: boolean) {
    this.store.dispatch(new Actions.SetSuccessOnly(value));
  }

  public setIncludeNotMatchableSpecs(value: boolean) {
    this.store.dispatch(new Actions.SetIncludeNotMatchableSpecs(value));
  }

  public setBdsIds(ids: string[]) {
    this.store.dispatch(new Actions.SetBdsIds(ids));
  }

  public setDataSource(dataSource: BindingDataSource) {
    this.store.dispatch(new Actions.SetDataSource(dataSource));
  }

  public check(isNomenclature?: boolean) {
    this.store.dispatch(new Actions.Check(isNomenclature));
  }
}
