<pik-card>
  <div class="entity-check-title">
    <h2>{{ 'BINDINGS_CHECK_MODULE.TITLE' | translate }}</h2>
    <fm-hint link="https://fm-help.bimteam.app/{{ 'LOCALIZATION' | translate }}/web/bindings-check"></fm-hint>
  </div>

  <div class="entity-check-wrap">
    <div class="entity-check-content">
      <div class="entity-check-content__item">
        <h3>{{ 'COMMON_LABELS.BINDING_TYPE' | translate }}</h3>
        <div class="entity-check-content__row">
          <app-binding-source-type-control [formControl]="sourceTypeControl"></app-binding-source-type-control>
        </div>
        <div class="entity-check-content__row">
          <pik-switch (change)="bindingsCheckService.setSuccessOnly($event)">
            {{ 'BINDINGS_CHECK_MODULE.ONLY_SUCCESS' | translate }}
          </pik-switch>
        </div>
      </div>
      <div class="entity-check-content__item">
        <h3>{{ 'COMMON_LABELS.DISCIPLINE' | translate }}</h3>
        <div class="entity-check-content__row">
          <app-discipline-control
            [formControl]="disciplineControl"
            (selectFull)="bindingsCheckService.setDiscipline($event)"
          ></app-discipline-control>
        </div>
      </div>
      <div class="entity-check-content__item">
        <h3>{{ 'DATASOURCE_CHECK_BINDINGS' | translate }}</h3>
        <div class="entity-check-content__row">
          <pik-select
            class="entity-check-content__source-types"
            placeholder="{{ 'DATASOURCE_CHECK_BINDINGS' | translate }}"
            appearance="outline"
            #bindingsCheckSourceTypeLink
            [formControl]="bindingsCheckSourceType"
            (change)="onBindingsCheckSourceTypeChange($event)"
          >
            <pik-option *ngFor="let source of bindingsCheckSourceTypes" [value]="source.id">{{
              source.name
            }}</pik-option>
          </pik-select>
        </div>
      </div>
      <ng-container [ngSwitch]="bindingsCheckSourceType.value">
        <ng-container *ngSwitchCase="EBindingsCheckSourceType.BDS">
          <app-bds-ids-chips [BDSIds]="BDSIds" (onBDSIdsChange)="BDSIds = $event"></app-bds-ids-chips>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <div class="entity-check-content__item">
            <h3>{{ 'COMMON_LABELS.FAMILY' | translate }}</h3>

            <div class="entity-check-content__row">
              <app-family-control
                [formControl]="familyControl"
                (selectFull)="selectFamily($event)"
                [sortOrderFamily]="SortOrder.Descending"
              ></app-family-control>
              <app-family-version-control
                [formControl]="versionControl"
                [familyId]="(bindingsCheckService.family$ | async)?.id"
                (selectFull)="bindingsCheckService.setVersion($event)"
              ></app-family-version-control>

              <app-family-link
                *ngIf="familyControl.value"
                class="angle-link"
                [data]="{
                  id: familyControl.value,
                  versionNumber: (bindingsCheckService.version$ | async)?.version,
                  functionalTypeId: functionalTypeControl.value
                }"
              >
                <svg-icon src="./assets/icons/tree-angle.svg" [stretch]="true"></svg-icon>
              </app-family-link>
            </div>
            <div class="entity-check-content__row">
              <app-family-symbol-control
                #symbolControlComponent
                [formControl]="symbolControl"
                [versionId]="versionControl.value"
                (selectFull)="bindingsCheckService.setSymbol($event)"
              ></app-family-symbol-control>
            </div>
            <div class="entity-check-content__row">
              <app-family-set-control
                [formControl]="setControl"
                [sets]="symbolControlComponent.activeSets$ | async"
                (selectFull)="bindingsCheckService.setFamilyVersionSet($event)"
              ></app-family-set-control>
            </div>
            <div class="entity-check-content__row" *ngIf="familyControl.value">
              <app-family-ft-control
                [formControl]="functionalTypeControl"
                [functionalTypeList]="functionalTypeList$ | async"
                size="small"
              ></app-family-ft-control>

              <button
                *ngIf="(bindingsCheckService.familyParameters$ | async)?.checkParameters?.length"
                pikButton
                appearance="inverse"
                class="parameters-button"
                (click)="openFamilyParametersDrawer()"
              >
                <svg-icon src="./assets/icons/table-settings.svg" pikButtonIcon position="before"></svg-icon>
                {{ 'COMMON_LABELS.PARAMETERS' | translate }}
                <pik-tag [counter]="true">{{
                  (bindingsCheckService.familyParameters$ | async)?.validParametersCount
                }}</pik-tag>
              </button>
            </div>
          </div>
          <div class="entity-check-content__item">
            <h3>{{ 'COMMON_LABELS.MATERIALS' | translate }}</h3>
            <div class="entity-check-content__row">
              <app-bindings-check-materials
                (editMaterialParameters)="
                  parametersDrawer.open(
                    $event.localId,
                    $event.parameters.checkParameters,
                    BindingsCheckParametersDrawerEnum.Material
                  )
                "
              ></app-bindings-check-materials>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <button pikButton [disabled]="!versionControl.value && !BDSIds?.length" (click)="bindingsCheckService.check()">
        {{ 'COMMON.CHECK' | translate }}
      </button>
    </div>

    <app-bindings-check-tree></app-bindings-check-tree>
  </div>
</pik-card>

<app-bindings-check-parameters-drawer
  #parametersDrawer
  (onSave)="bindingsCheckService.setCheckParameters($event)"
></app-bindings-check-parameters-drawer>
