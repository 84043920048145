export enum EBindingsCheckSourceType {
  Family,
  BDS,
}

export const bindingsCheckSourceTypes = [
  {
    id: EBindingsCheckSourceType.Family,
    name: 'Family Manager',
  },
  {
    id: EBindingsCheckSourceType.BDS,
    name: 'BDS',
  },
];
